import React from "react"
import { Link } from "gatsby"
import styles from "../styles/common.module.scss"

import Logo from "../images/logo.png"

const FooterWrapper = () => {
  return (
    <div className={styles.footerWrapper}>
      <footer className={styles.footer}>
        <div className={styles.footerLogoWrapper}>
          <img
            src={Logo}
            alt="Jenga Tech Solutions Limited Logo"
            className={styles.footerLogo}
          />
        </div>

        <div className={styles.footerTop}>
          <div className={styles.footerContentItem}>
            <h2>Company</h2>
            <Link to="/join#careers" className={styles.btnLink}>
              Career
            </Link>
            {/*<Link to="#" className={styles.btnLink}>*/}
            {/*  Covid 19 Kenya*/}
            {/*</Link>*/}
          </div>

        </div>

        <div className={styles.footerBottom}>
          <div className={styles.footerContentItem}>
            <h2 id={"location"}>Location</h2>
            <p>Nairobi Garage Pinetree Plaza Nairobi, Kenya</p>
          </div>
        </div>
      </footer>

      <hr className={styles.footerCopyrightSeparator} />
      <p className={styles.footerCopyright}>
        ©{new Date().getFullYear()} Jenga Tech Solutions Limited
      </p>
    </div>
  )
}

export default FooterWrapper
