import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { motion, useViewportScroll, useTransform } from "framer-motion"

import Logo from "../images/logo.png"
import styles from "../styles/common.module.scss"

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [shadeHeader, setShadeHeader] = useState(false)
  const { scrollYProgress } = useViewportScroll()
  const yRange = useTransform(scrollYProgress, [0, 0.9], [0, 1])

  useEffect(() => yRange.onChange(v => setShadeHeader(v > 0)), [yRange])

  const iconVariants = {
    open: {
      y: 8,
      rotate: 45,
      background: "black",
    },
    closed: {
      y: 0,
      rotate: 0,
      background: "black",
    },
    openReverse: {
      y: -8,
      rotate: -45,
      background: "black",
    },
  }

  const menuVariants = {
    open: {
      opacity: 1,
    },
    closed: {
      opacity: 0,
    },
  }

  return (
    <>
      <header className={styles.mobileHeader}>
        <Link to="/">
          <img
            src={Logo}
            alt="Jenga Tech Solutions Limited Company Logo"
            className={styles.mobileHeaderLogo}
          />
        </Link>

        <motion.div
          className={styles.menuIconWrapper}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <motion.div
            className={styles.menuIcon}
            variants={iconVariants}
            animate={menuOpen ? "open" : "closed"}
            transition={{ type: "tween" }}
          ></motion.div>
          <motion.div
            className={styles.menuIcon}
            animate={menuOpen ? { opacity: 0 } : { opacity: 1 }}
          ></motion.div>
          <motion.div
            className={styles.menuIcon}
            variants={iconVariants}
            animate={menuOpen ? "openReverse" : "closed"}
            transition={{ type: "tween" }}
          ></motion.div>
        </motion.div>
      </header>
      <header className={styles.headerWrapper}>
        <div className={styles.header}>
          <Link to="/" className={styles.headerBrand}>
            <img
              src={Logo}
              alt="Jenga Tech Solutions Limited Company Logo"
              className={styles.logo}
            />
          </Link>

          <div className={styles.headerItems}>
            <Link to="/join#careers" className={styles.btnOutlinePrimary}>
              Join Us
            </Link>
          </div>
        </div>
      </header>
      {menuOpen && (
        <motion.div
          className={styles.menu}
          variants={menuVariants}
          animate={menuOpen ? "open" : "closed"}
        >
          <Link to="/" className={styles.menuItem}>
            Home
          </Link>
          <Link to="/join" className={styles.menuItem}>
            Join Us
          </Link>
          <Link
            to="/#contact"
            className={styles.menuItem}
            onClick={() => setMenuOpen(false)}
          >
            Contact Us
          </Link>
        </motion.div>
      )}
    </>
  )
}

export default Header
